import { createSlice } from '@reduxjs/toolkit'
import queryS from '../../services/helpers/query'

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    products: [],
    product: {},
  },
  reducers: {
    productsAdded(state, { payload: { products } }) {
      state.products = products
    },
    productAdded(state, { payload: { queryString, product } }) {
      state.product[queryString] = product
    },
  },
  extraReducers: {},
})

const getProducts = (state) => {
  return state.products.products.length ? state.products.products : null
}

const getProduct = (state) => (query) => {
  return state.products.product[queryS(query)]
}

const fetchProducts = () => async (dispatch, getState, { api }) => {
  const products = getProducts(getState())

  if (products) {
    return products
  }

  const response = await api.recipes()
  dispatch(productsSlice.actions.productsAdded({ products: response }))

  return response
}

const fetchProduct = (slug) => async (dispatch, getState, { api }) => {
  const queryString = queryS({ slug })
  const cachedProduct = getProduct(getState())({ slug })

  if (cachedProduct) {
    return cachedProduct
  }

  const response = await api.recipes({
    slug,
  })
  const product = response

  dispatch(productsSlice.actions.productAdded({ queryString, product }))

  return product
}

export default {
  getProducts,
  getProduct,
  fetchProduct,
  fetchProducts,
  reducer: productsSlice.reducer,
}
